import axios from "@/axios.js";

export default {
  async fetchBuses({ commit }) {
    try {
      const { data } = await axios.get(`/shuttle/bus-station`);
      commit("setBuses", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchBus({ commit }, params) {
    try {
      const { data } = await axios.get(`/shuttle/bus-station/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeBus({ commit }, params) {
    try {
      const { data } = await axios.post(`/shuttle/bus-station`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateBed({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/shuttle/bus-station/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeBus({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/shuttle/bus-station/${params}`);
      dispatch("fetchBuses");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
